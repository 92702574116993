













import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ActionMethod } from 'vuex';
import { debounce } from 'lodash';
import Team from '@improve/common-utils/src/model/Team';
import Ticket from '@improve/common-utils/src/model/Ticket';
import BaseTextInput from '../core/BaseTextInput.vue';
import BaseUserSearch from './BaseUserSearch.vue';

@Component({
  name: 'BaseGlobalSearch',
  components: {
    BaseTextInput,
    BaseUserSearch
  }
})
export default class BaseGlobalSearch extends Vue {
  @Action searchTickets!: ActionMethod;

  @Action searchTeams!: ActionMethod;

  @Getter foundTickets?: Array<Ticket>;

  @Getter foundTeams?: Array<Team>;

  private search = '';

  private searchInProgress = false;

  private debounceSearchInput = debounce((event: string) => {
    this.fetchAllInContext(event);
  }, 300);

  async fetchAllInContext(searchName: string): Promise<void> {
    if (searchName.length < 3) {
      if (this.$route.name === 'SearchResults') {
        this.$router.go(-1);
      }
      return;
    }
    this.searchInProgress = true;
    await this.searchTickets(searchName.toLowerCase());
    await this.searchTeams(searchName.toLowerCase());
    this.searchInProgress = false;
    this.$router.push({ name: 'SearchResults', query: { search: searchName.toLowerCase() } });
  }
}
