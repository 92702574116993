



















import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import { Action, Getter } from 'vuex-class';
import { ActionMethod } from 'vuex';
import { Route } from 'vue-router';
import AppLogos from '@improve/common-components/src/components/widgets/AppLogos.vue';
import UserMenu from '@improve/common-components/src/components/widgets/UserMenu.vue';
import { MenuOption } from '@improve/common-utils/src/types/MenuOption';
import iconPlusCircle from '@improve/common-components/src/assets/icon-plus-circle.svg';
import iconUser from '@improve/common-components/src/assets/icon-user.svg';
import iconCog from '@improve/common-components/src/assets/icon-cog.svg';
import iconLogout from '@improve/common-components/src/assets/icon-logout.svg';
import User from '@improve/common-utils/src/model/User';
import BaseGlobalSearch
  from '@improve/common-components/src/components/widgets/BaseGlobalSearch.vue';

@Component({
  name: 'Header',
  components: {
    AppLogos,
    UserMenu,
    BaseGlobalSearch
  }
})
export default class Header extends Vue {
  @Getter currentUser?: User;

  @Getter hasCompletedProfile!: boolean;

  @Action logout!: ActionMethod;

  loading = false;

  menuOptions: Array<MenuOption> = [
    {
      title: 'menu.createImprove',
      icon: iconPlusCircle,
      onClick: (): Promise<Route> => this.$router.push({ name: 'CreateTicket' })
    },
    {
      title: 'menu.myProfile',
      icon: iconUser,
      onClick: (): Promise<Route> => this.$router.push({ name: 'UserProfile' })
    },
    {
      title: 'menu.settings',
      icon: iconCog,
      onClick: (): Promise<Route> => this.$router.push({ name: 'EditProfile' })
    },
    {
      title: 'menu.logOut',
      icon: iconLogout,
      onClick: (): void => this.doLogout()
    }
  ];

  get showSearch(): boolean {
    return !this.$route.meta?.publicAccess;
  }

  doLogout(): void {
    this.logout();
  }

  goHome(): void {
    if (!this.currentUser || this.hasCompletedProfile) {
      this.$router.push({
        name: this.currentUser ? 'Dashboard' : 'Login'
      });
    }
  }
}
