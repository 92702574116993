























import 'reflect-metadata';
import { Component, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Vue from 'vue';
import { Route } from 'vue-router';
import Header from '@/components/core/Header.vue';
import Footer from '@/components/core/Footer.vue';
import Snackbar from '@improve/common-components/src/components/widgets/Snackbar.vue';
import styleVariables from '@improve/common-components/src/styles/export.scss';

@Component({
  components: {
    Footer,
    Header,
    Snackbar
  }
})
export default class App extends Vue {
  @Getter isAuthenticated!: boolean;

  showNavFlag = false;

  customParentClass = '';

  hideNavRoutes: string[] = [
    'ForgotPassword',
    'Register',
    'Login',
    'SetPassword',
    'ResetPassword'
  ];

  styleVariables = styleVariables;

  @Watch('$route', {
    immediate: true,
    deep: true
  })
  showNav(newVal: Route): void {
    if (!newVal.name) {
      this.showNavFlag = false;
      return;
    }
    this.showNavFlag = !this.hideNavRoutes.includes(newVal.name);
    this.getParentClassName();
  }

  getParentClassName(): void {
    let path = this.$route.path || '';
    if (!path.length) {
      return;
    }
    path = path.replaceAll('/', ' ').trim().split(' ').join('-');
    this.customParentClass = path.length ? path : 'dashboard';
  }
}
