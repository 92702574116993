














import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import { Getter } from 'vuex-class';
import Organization from '@improve/common-utils/src/model/Organization';
import User from '@improve/common-utils/src/model/User';

@Component({
  name: 'FooterLinks'
})
export default class FooterLinks extends Vue {
  @Getter currentOrganization!: Organization;

  @Getter currentUser!: User;

  tcLink = '';

  links: Array<any> = [];

  footerLinks(): void {
    this.links = [
      {
        title: this.$t('menu.getInContact').toString(),
        href: 'mailto:lean.team@swisscom.com'
      },
      {
        title: this.$t('menu.learnMore').toString(),
        href: 'https://swisscom.sharepoint.com/sites/simplicity/SitePages/Simplicity.aspx'
      },
      {
        title: this.$t('menu.privacyAndTerms').toString(),
        href: this.tcLink
      }
    ];
  }

  created(): void {
    this.tcLink = this.currentOrganization.getTermsAndConditionsLink(this.currentUser.language) || '';
    this.footerLinks();
  }
}
