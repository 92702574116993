
























import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import FooterLogo from '@improve/common-components/src/components/widgets/FooterLogo.vue';
import FooterLinks from '@improve/common-components/src/components/widgets/FooterLinks.vue';

@Component({
  name: 'Footer',
  components: {
    FooterLogo,
    FooterLinks
  }
})
export default class Footer extends Vue {

}
