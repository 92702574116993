





































import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { Getter } from 'vuex-class';
import { MenuOption } from '@improve/common-utils/src/types/MenuOption';
import User from '@improve/common-utils/src/model/User';
import Organization from '@improve/common-utils/src/model/Organization';
import BaseUserAvatar from './BaseUserAvatar.vue';

@Component({
  name: 'Header',
  components: {
    BaseUserAvatar
  }
})
export default class Header extends Vue {
  @Prop({ default: [] }) menuOptions!: Array<MenuOption>;

  @Getter currentUser?: User;

  @Getter currentOrganization?: Organization;

  loading = false;
}
