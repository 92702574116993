import { NavigationGuardNext, Route, RouteConfig } from 'vue-router';
import VueRouter from '@improve/common-utils/src/router/index';
import FEATURE from '@improve/common-utils/src/model/Feature';
import store from '../store';

const importRouteComponents = (route: RouteConfig, basePath: string) => ({
  component: () => import(/* webpackChunkName: "[request]" */ `../${basePath}${route.name}.vue`),
  // TODO: Just to make it easy to disable everything,
  //  remove this meta block when we have actual feature
  meta: {
    feature: FEATURE.IMPROVE_PORTAL_OTHER
  },
  ...route
});

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'Login',
    props: true,
    meta: {
      publicAccess: true
    }
  },
  {
    path: '/login/sso',
    name: 'LoginSSO',
    props: true,
    meta: {
      publicAccess: true
    }
  },
  {
    path: '/register',
    name: 'Register',
    meta: {
      publicAccess: true
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    props: true,
    meta: {
      publicAccess: true
    }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    // Here the component name is different route name, which is not usable in the map function
    component: () => import(/* webpackChunkName: "SetPassword" */ '../views/SetPassword.vue'),
    props: {
      resetPasswordRoute: true
    },
    meta: {
      publicAccess: true
    }
  },
  {
    path: '/set-password',
    name: 'SetPassword',
    props: {
      setPasswordRoute: true
    },
    meta: {
      publicAccess: true
    }
  },
  {
    path: '/',
    name: 'Dashboard'
  },
  {
    path: '/search-results',
    name: 'SearchResults'
  },
  {
    path: '/search-all-results',
    name: 'SearchAllResults'
  },
  {
    path: '/create-ticket',
    name: 'CreateTicket'
  },
  {
    path: '/edit-ticket/:id',
    name: 'EditTicket'
  },
  {
    path: '/ticket/:id',
    name: 'TicketDetails'
  },
  {
    path: '/ticket/:id/thank-you',
    name: 'TicketThanks'
  },
  {
    path: '/complete-profile',
    name: 'CompleteProfile'
  },
  {
    path: '/edit-profile',
    name: 'EditProfile'
  },
  {
    path: '/create-virtual-team',
    name: 'CreateVirtualTeam'
  },
  {
    path: '/edit-virtual-team/:id',
    name: 'EditVirtualTeam'
  },
  {
    path: '/team-details/:id',
    name: 'TeamDetails',
    children: [
      {
        path: 'members',
        name: 'TeamMembersTab',
        component: () => import(/* webpackChunkName: "team-improves-tab" */ '../components/team/TeamMembersTab.vue')
      },
      {
        path: 'improves',
        name: 'TeamImprovesTab',
        component: () => import(/* webpackChunkName: "team-members-tab" */ '../components/team/TeamImprovesTab.vue')
      }
    ],
    redirect: '/team-details/:id/improves'
  },
  {
    path: '/topic-details/:id',
    name: 'TopicDetails',
    children: [
      {
        path: 'improves',
        name: 'TopicImproves',
        component: () => import(/* webpackChunkName: "topic-improves-tab" */ '../components/topic/TopicImproves.vue')
      },
      {
        path: 'experts',
        name: 'TopicExperts',
        component: () => import(/* webpackChunkName: "topic-experts-tab" */ '../components/topic/TopicExperts.vue')
      }
    ],
    redirect: '/topic-details/:id/improves'
  },
  {
    path: '/profile',
    name: 'UserProfile',
    children: [
      {
        path: 'my-improves',
        name: 'MyImprovesTab'
      },
      {
        path: 'assignments',
        name: 'AssignmentsTab'
      },
      {
        path: 'implemented',
        name: 'ImplementedTab'
      },
      {
        path: 'teams',
        name: 'TeamsTab'
      },
      {
        path: 'topics',
        name: 'TopicsTab'
      },
      {
        path: 'my-profile',
        name: 'AboutTab'
      }
    ]
      .map((route: RouteConfig) => importRouteComponents(route, 'components/user/myprofile/')),
    redirect: '/profile/my-improves'
  },
  {
    path: '*',
    redirect: '/',
    meta: {
      publicAccess: true
    }
  }
]
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  .map((route: RouteConfig) => importRouteComponents(route, 'views/'));

const router = VueRouter(routes, store);
router.beforeEach(async (
  to: Route,
  from: Route,
  next: NavigationGuardNext
) => {
  if (to.name !== 'CompleteProfile'
    && store.getters.currentUser
    && !store.getters.currentUser?.needToAcceptLatestTC(store.getters.currentOrganization)
    && !store.getters.hasCompletedProfile) {
    return next({ name: 'CompleteProfile' });
  }
  return next();
});

export default router;
