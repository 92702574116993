import { render, staticRenderFns } from "./BaseGlobalSearch.vue?vue&type=template&id=c9465092&scoped=true&v-slot%3Aheader=true&"
import script from "./BaseGlobalSearch.vue?vue&type=script&lang=ts&"
export * from "./BaseGlobalSearch.vue?vue&type=script&lang=ts&"
import style0 from "./BaseGlobalSearch.vue?vue&type=style&index=0&id=c9465092&lang=scss&scoped=true&"
import style1 from "./BaseGlobalSearch.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c9465092",
  null
  
)

export default component.exports